import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _45237613 = () => interopDefault(import('../src/pages/flights.vue' /* webpackChunkName: "pages/flights" */))
const _5b9940da = () => interopDefault(import('../src/pages/lounge.vue' /* webpackChunkName: "pages/lounge" */))
const _2b3b5f94 = () => interopDefault(import('../src/pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _0343d930 = () => interopDefault(import('../src/pages/tablo.vue' /* webpackChunkName: "pages/tablo" */))
const _678e4c8a = () => interopDefault(import('../src/pages/for-big-planes/contacts.vue' /* webpackChunkName: "pages/for-big-planes/contacts" */))
const _97820e72 = () => interopDefault(import('../src/pages/for-passengers/feedback.vue' /* webpackChunkName: "pages/for-passengers/feedback" */))
const _545873c5 = () => interopDefault(import('../src/pages/for-passengers/naiti-oplatit-zakaz.vue' /* webpackChunkName: "pages/for-passengers/naiti-oplatit-zakaz" */))
const _7031d704 = () => interopDefault(import('../src/pages/for-passengers/proverit-skachat-bilet.vue' /* webpackChunkName: "pages/for-passengers/proverit-skachat-bilet" */))
const _4cb12eb1 = () => interopDefault(import('../src/pages/for-passengers/schedule-of-flights.vue' /* webpackChunkName: "pages/for-passengers/schedule-of-flights" */))
const _4d2c1891 = () => interopDefault(import('../src/pages/for-passengers/zayavka_na_vozvratobmen_bileta.vue' /* webpackChunkName: "pages/for-passengers/zayavka_na_vozvratobmen_bileta" */))
const _4435f362 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _520d0f22 = () => interopDefault(import('../src/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/flights",
    component: _45237613,
    meta: {"name":"flights","layout":"index"},
    name: "flights"
  }, {
    path: "/lounge",
    component: _5b9940da,
    meta: {"name":"lounge","layout":"index"},
    name: "lounge"
  }, {
    path: "/news",
    component: _2b3b5f94,
    meta: {"name":"index","layout":"index"},
    name: "news"
  }, {
    path: "/tablo",
    component: _0343d930,
    meta: {"name":"tablo","layout":"index"},
    name: "tablo"
  }, {
    path: "/for-big-planes/contacts",
    component: _678e4c8a,
    meta: {"name":"contacts","layout":"index"},
    name: "for-big-planes-contacts"
  }, {
    path: "/for-passengers/feedback",
    component: _97820e72,
    meta: {"name":"feedback","layout":"index"},
    name: "for-passengers-feedback"
  }, {
    path: "/for-passengers/naiti-oplatit-zakaz",
    component: _545873c5,
    meta: {"name":"bids-request","layout":"index"},
    name: "for-passengers-naiti-oplatit-zakaz"
  }, {
    path: "/for-passengers/proverit-skachat-bilet",
    component: _7031d704,
    meta: {"name":"bids-request","layout":"index"},
    name: "for-passengers-proverit-skachat-bilet"
  }, {
    path: "/for-passengers/schedule-of-flights",
    component: _4cb12eb1,
    meta: {"name":"schedule","layout":"index"},
    name: "for-passengers-schedule-of-flights"
  }, {
    path: "/for-passengers/zayavka_na_vozvratobmen_bileta",
    component: _4d2c1891,
    meta: {"name":"bids-request","layout":"index"},
    name: "for-passengers-zayavka_na_vozvratobmen_bileta"
  }, {
    path: "/",
    component: _4435f362,
    meta: {"name":"index","layout":"index"},
    name: "index"
  }, {
    path: "/*",
    component: _520d0f22,
    meta: {"name":"dynamic-route","layout":"index"},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
