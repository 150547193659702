import Vue from 'vue';
import axios from 'axios';

export default class Sender {

  constructor({ baseUrl}) {
    this.request = axios.create({
      baseURL: baseUrl,
      timeout: 25000,
      validateStatus() {
        return true;
      },
    });
    this.token = axios.CancelToken.source();
    this.redirectUrl = '/';

    this.options = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      cancelToken: this.token.token,
    };

    this.options.headers['Accept-Language'] = Vue.prototype.$locale ? Vue.prototype.$locale : 'ru';
  }

  get(url) {
    return this.request.get(url, this.options)
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        this.sendError(error);
      });
  }

  post(url, data, _callback) {
    if (typeof _callback === 'undefined' || _callback === null) {
      return this.request.post(url, data, this.options).then(response => {
        return Promise.resolve(response);
      });
    }
    return this.request.post(url, data, this.options)
      .then(response => {
        return Promise.resolve(response);
      })
      .then(_callback)
      .catch(error => {
        this.sendError(error);
      });
  }

  patch(url, data, _callback) {
    if (typeof _callback === 'undefined' || _callback === null) {
      return this.request.patch(url, data, this.options).then(response => {
        return Promise.resolve(response);
      });
    }
    return this.request.patch(url, data, this.options)
      .then(response => {
        return Promise.resolve(response);
      })
      .then(_callback)
      .catch(error => {
        this.sendError(error);
      });
  }

  put(url, data, _callback) {
    if (typeof _callback === 'undefined' || _callback === null) {
      return this.request.put(url, data, this.options).then(response => {
        return Promise.resolve(response);
      });
    }
    return this.request.put(url, data, this.options)
      .then(response => {
        return Promise.resolve(response);
      })
      .then(_callback)
      .catch(error => {
        this.sendError(error);
      });
  }

  delete(url, _callback) {
    if (typeof _callback === 'undefined' || _callback === null) {
      return this.request.delete(url, this.options).then(response => {
        return Promise.resolve(response);
      });
    }
    return this.request.delete(url, this.options)
      .then(response => {
        return Promise.resolve(response);
      })
      .then(_callback)
      .catch(error => {
        this.sendError(error);
      });
  }

  sendError(error) {
    const _this = this;

    if (typeof _this.form !== 'undefined') {
      _this.form.$noty.error(String(error));
    }
  }
}